export class PageConfig {
  public defaults: any = {
    dashboard: {
      page: {
        title: 'Dashboard',
        desc: 'Latest updates and statistic charts'
      },
    },
    header: {
      actions: {
        page: {title: 'Actions', desc: 'Actions example page'}
      }
    },
    profile: {
      page: {title: 'User Profile', desc: ''}
    },
    venues: {
      page: {title: 'Venues'},
    },
    groups: {
      page: {title: 'Gruppi'}
    },
    promotions: {
      page: {title: 'Promozioni'}
    },
    error: {
      404: {
        page: {title: '404 Not Found', desc: '', subheader: false}
      },
      403: {
        page: {title: '403 Access Forbidden', desc: '', subheader: false}
      }
    },
  };

  public get configs(): any {
    return this.defaults;
  }
}
