import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../core/api.service';
import { ActivatedRoute } from '@angular/router';
import { Group } from '../../../model/group';
import { Venue } from '../../../model/venue';
import { Observable } from 'rxjs';
import { Promotion } from '../../../model/promotion';

@Component({
  selector: 'ab-group',
  templateUrl: './group.component.html'
})
export class GroupComponent implements OnInit {
  group: Group
  venues: Venue[];
  allVenues: Venue[];
  promotions: Promotion[]
  allPromotions: Promotion[]

  constructor(
    private apiservice: ApiService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(async params => {
      this.group = await this.apiservice.getGroup(params.get("id"))
      this.allVenues = await this.apiservice.getVenues()
      this.venues = await this.apiservice.getVenues(this.group)
      this.allPromotions = await this.apiservice.getPromotions()
      this.promotions = await this.apiservice.getPromotions(this.group)
    })
  }


  hasVenue(v:Venue) {
    return this.venues.indexOf(v) > -1
  }

  toggleVenue(v:Venue) {
    var isin = this.venues.indexOf(v) > -1
    if (!isin)
      this.venues.push(v)
    else
      this.venues.splice(this.venues.indexOf(v),1)

    if (!v.groups) v.groups = []

    v.groups.push(this.group)
  }

  hasPromotion(p:Promotion) {
    return this.promotions.indexOf(p) > -1
  }

  togglePromotion(promotion:Promotion) {
    var isin = this.promotions.indexOf(promotion) > -1
    if (!isin)
      this.promotions.push(promotion)
    else
      this.promotions.splice(this.promotions.indexOf(promotion),1)

    if (!promotion.groups) promotion.groups = []

    promotion.groups.push(this.group)
  }




}
