import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'gt-operator-day-cell',
  templateUrl: './operator-day-cell.component.html',
  styleUrls: ['./operator-day-cell.component.scss']
})
export class OperatorDayCellComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
