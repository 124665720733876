import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../core/api.service';
import { Promotion, Validity, ValidityTime, ValidityCase } from '../../../model/promotion';
import { Group } from '../../../model/group';
import { Venue } from '../../../model/venue';

@Component({
  selector: 'ab-promotion',
  templateUrl: './promotion.component.html'
})
export class PromotionComponent implements OnInit {
  // models
  promotion: Promotion
  groups: Group[]
  allGroups: Group[]
  venues: Venue[]
  allVenues: Venue[]
  
  // transient properties x master/slave view 
  validity: Validity 
  validityTime: ValidityTime;
  validityCase: ValidityCase;

  constructor(
    private apiservice: ApiService,
    private activatedRoute: ActivatedRoute
  ) { }
  
  
  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(async params => {
      this.promotion = await this.apiservice.getPromotion(params.get("id"))
      this.groups = await this.apiservice.getGroups(this.promotion)
      this.allGroups = await this.apiservice.getGroups()
      this.venues = await this.apiservice.getVenues(this.promotion)
      this.allVenues = await this.apiservice.getVenues()
    })
  }

  toggleVenue(venue) {
    var a = this.venues.find(g => g.id == venue.id)
    if (!a)
      this.venues.push(venue)
    else
      this.venues.splice(this.groups.indexOf(a),1)

    venue.promotions.push(this.promotion)
  }

  hasVenue(id:string) {
    return this.venues.find(g => g.id == id) ? true : false
  }


  toggleGroup(group) {
    var a = this.groups.find(g => g.id == group.id)
    if (!a)
      this.groups.push(group)
    else
      this.groups.splice(this.groups.indexOf(a),1)

    group.promotions.push(this.promotion)
  }
  
  hasGroup(id:string) {
     return this.groups.find(g => g.id == id) ? true : false
  }

  addValidity() {
    let v = {startDate:"10/10/2020", endDate:"10/11/202"}
    this.promotion.validity.push(v)
    this.resetSelection()
    this.validity = v
  }

  removeValidity() {
    this.promotion.validity.splice(this.promotion.validity.indexOf(this.validity),1)
    this.resetSelection()
  }

  addValidityTime() {
    let v = {startTime:"10:20", endTime:"14:00"}
    this.promotion.validityTimes.push(v)
    this.resetSelection()
    this.validityTime = v
  }
  
  removeValidityTime() {
    this.promotion.validityTimes.splice(this.promotion.validityTimes.indexOf(this.validityTime),1)
    this.resetSelection()
  }

  addValidityCase() {
    let v = {}
    this.promotion.validityCases.push(v)
    this.resetSelection()
    this.validityCase = v
  }
  
  removeValidityCase() {
    this.promotion.validityCases.splice(this.promotion.validityCases.indexOf(this.validityCase),1)
    this.resetSelection()
  }
  
  resetSelection() {
    this.validity = null
    this.validityCase = null
    this.validityTime = null
  }

}
