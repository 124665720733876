import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../core/api.service';
import { Promotion } from '../../../model/promotion';

@Component({
  selector: 'ab-promotions',
  templateUrl: './promotions.component.html'
})
export class PromotionsComponent implements OnInit {
  promotions: Promotion[]

  constructor(
    private apiservice : ApiService
  ) { }

  async ngOnInit() {
    this.promotions = await this.apiservice.getPromotions()
  }

  newPromotion() {
    this.apiservice.newPromotion()
  }
}
