import { Group } from './group'
import { Venue } from './venue'

export class Validity {
    startDate: string
    endDate: string
}

export class ValidityTime {
    startTime: string
    endTime: string
    monday?: boolean
    tuesday?: boolean
    wednesday?: boolean
    thursday?: boolean
    friday?: boolean
    saturday?: boolean
    sunday?: boolean
}

export class ValidityCase {
    minCovers?: number
    maxCovers?: number
    limitPerDay?: number
    thresholdAmount?: number
    thresholdCovers?: number
}

export class Promotion {
    id: string = Math.random().toString()
    name: string = "New Promotion"
    description: string
    discountAmount: number
    groups: Group[] = []
    venues: Venue[] = []
    validity: Validity[] = []
    validityTimes: ValidityTime[] = []
    validityCases: ValidityCase[] = []
}