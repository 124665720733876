import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'gt-selection-toolbar',
  templateUrl: './selection-toolbar.component.html',
  styleUrls: ['./selection-toolbar.component.scss']
})
export class SelectionToolbarComponent implements OnInit {

  operators = ['Marco Rossi', 'Salvatore Cruciani'];
  groups = ['Pavia', 'Lodi Giardinaggio'];

  selectedOperator = '';
  selectedGroup = '';
  selectedDate = new Date();

  constructor() {
  }

  ngOnInit() {
  }

}
