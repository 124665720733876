import { Injectable } from '@angular/core';
import { Group } from '../model/group';
import  *  as  groups  from  '../model/groups.json';
import { Venue } from '../model/venue';
import  *  as  venues  from  '../model/venues.json';
import { Promotion } from '../model/promotion';
import  *  as  promotions  from  '../model/promotions.json';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
 

  public $busy:BehaviorSubject<boolean> = new BehaviorSubject(false)// notify ui that app is busy

  private venues:Venue[]
  private groups:Group[]
  private promotions: Promotion[] 

  constructor() { 
    this.groups = groups.groups
    this.venues = venues.venues 
    this.promotions = promotions.promotions
  }

  
  public getGroups(parent?: Venue|Promotion):Promise<Group[]> {
    if (parent) {
      if (!parent.groups) parent.groups = []
      return Promise.resolve(parent.groups)
    } else
      return Promise.resolve(this.groups)
  }

  public getGroup(id:string):Promise<Group> {
    return Promise.resolve(this.groups.find(g => g.id == id))
  }

  public newGroup():Venue {
    let n:Group = {id: Math.random().toString(), name: "New group", description: ""};
    this.groups.push(n)
    return n
  }

  public getVenues(parent?: Promotion|Group):Promise<Venue[]> {
    if (parent) {
      if (!parent.venues) parent.venues = []
      return Promise.resolve(parent.venues)
    } 
    return Promise.resolve(this.venues)
  }

  public getVenue(id:string):Promise<Venue> {
    return Promise.resolve(this.venues.find(v => v.id == id))
  }

  public newVenue():Venue {
    let n:Venue = {id: Math.random().toString(), name: "New venue", description: ""};
    this.venues.push(n)
    return n
  }

  public getPromotions(parent?: Venue|Group):Promise<Promotion[]> {
    if (parent) {
      if (!parent.promotions) parent.promotions = []
      return Promise.resolve(parent.promotions)
    } 
    return Promise.resolve(this.promotions)
  }

  public getPromotion(id:string):Promise<Promotion> {
    return Promise.resolve(this.promotions.find(v => v.id == id))
  }


  public newPromotion():Venue {
    let p:Promotion = new Promotion();
    this.promotions.push(p)
    return p
  }



}
