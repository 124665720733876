import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../core/api.service';
import { Venue } from '../../../model/venue';

@Component({
  selector: 'ab-venues',
  templateUrl: './venues.component.html',
})
export class VenuesComponent implements OnInit {
  venues: Venue[]

  constructor(
    private apiservice : ApiService
  ) { }


  async ngOnInit() {
    this.venues = await this.apiservice.getVenues()
  }

  newVenue() {
    this.apiservice.newVenue()
  }
}
