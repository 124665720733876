import { Component, OnInit } from '@angular/core';
import { Group } from '../../../model/group';
import { ApiService } from '../../../core/api.service';


@Component({
  selector: 'gt-groups',
  templateUrl: './groups.component.html'
})
export class GroupsComponent implements OnInit {
  
  groups : Group[] 

  constructor(
    private apiservice : ApiService
  ) { }

  async ngOnInit() {
    this.groups = await this.apiservice.getGroups()
  }

  newGroup() {
    this.apiservice.newGroup()
  }
}
