export class MenuConfig {
  public defaults: any = {
    header: {
      self: {},
      items: []
    },
    aside: {
      self: {},
      items: [
        // {
        //   title: 'Dashboard',
        //   root: true,
        //   icon: 'flaticon2-architecture-and-city',
        //   page: '/dashboard',
        //   translate: 'MENU.DASHBOARD',
        //   bullet: 'dot'
        // },
        {
          title: 'Groups',
          root: true,
          icon: 'flaticon2-architecture-and-city',
          page: '/groups',
          translate: 'MENU.GROUPS',
          bullet: 'dot'
        },
        {
          title: 'Venues',
          root: true,
          icon: 'flaticon2-placeholder',
          page: '/venues',
          translate: 'MENU.VENUES',
          bullet: 'dot'
        },
        {
          title: 'Promotions',
          root: true,
          icon: 'flaticon-price-tag',
          page: '/promotions',
          translate: 'MENU.PROMOTIONS',
          bullet: 'dot'
        }
      ],
      
    },
  };

  public get configs(): any {
    return this.defaults;
  }
}
