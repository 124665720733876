// Angular
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
// Partials
import {PartialsModule} from '../partials/partials.module';
// Pages
import {CoreModule} from '../../core/core.module';
import {UserManagementModule} from './user-management/user-management.module';
import { RouterModule } from '@angular/router';
import { GroupComponent } from './group/group.component';
import { GroupsComponent } from './groups/groups.component';
import { VenueComponent } from './venue/venue.component';
import { VenuesComponent } from './venues/venues.component';
import { PromotionsComponent } from './promotions/promotions.component';
import { PromotionComponent } from './promotion/promotion.component';

@NgModule({
  declarations: [GroupComponent, GroupsComponent, VenueComponent, VenuesComponent, PromotionsComponent, PromotionComponent],
  exports: [],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    CoreModule,
    PartialsModule,
    UserManagementModule,
    RouterModule.forChild([
      {
        path: 'groups',
        component: GroupsComponent
      },
      {
        path: 'groups/:id',
        component: GroupComponent
      },
      {
        path: 'venues',
        component: VenuesComponent
      },
      {
        path: 'venues/:id',
        component: VenueComponent
      },
      {
        path: 'promotions',
        component: PromotionsComponent
      },
      {
        path: 'promotions/:id',
        component: PromotionComponent
      },
    ]),
  ],
  providers: [],
  
})
export class PagesModule {
}
