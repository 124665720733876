import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../core/api.service';
import { ActivatedRoute } from '@angular/router';
import { Venue } from '../../../model/venue';
import { Promotion } from '../../../model/promotion';

@Component({
  selector: 'ab-venue',
  templateUrl: './venue.component.html',
})
export class VenueComponent implements OnInit {
  venue: Venue
  promotions: Promotion[];
  allPromotions: Promotion[];

  constructor(
    private apiservice: ApiService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(async params => {
      this.venue = await this.apiservice.getVenue(params.get("id"))
      this.allPromotions = await this.apiservice.getPromotions()
      this.promotions = await this.apiservice.getPromotions(this.venue)
      console.log("PROMOTION ", this.promotions)
    })
  }

 
  hasPromotion(p:Promotion) {
    return this.promotions.indexOf(p) > -1
    // this.promotions.find(g => g.id == id) ? true : false
  }

  togglePromotion(promotion) {
    var isin = this.promotions.indexOf(promotion) > -1
    if (!isin)
      this.promotions.push(promotion)
    else
      this.promotions.splice(this.promotions.indexOf(promotion),1)

    if (!promotion.venues) promotion.venues = []
    promotion.venues.push(this.venue)
  }


  onAddPromotion() {
    this.apiservice.newPromotion()
  }


}
